import {Component, OnDestroy, OnInit} from '@angular/core';
import {MapboxService} from '../../services/mapbox/mapbox.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, startWith} from 'rxjs/operators';
import {combineLatest, Subscription} from 'rxjs';
import {LayersComponent} from '../layers/layers.component';
import {SearchComponent} from '../search/search.component';
import {ProjectsComponent} from '../projects/projects.component';
import {CompaniesComponent} from '../companies/companies.component';
import {SearchStateService} from '../../services/search-state.service';
import {PinnedLocationService} from '../../services/pinned-location.service';
import {ApiService} from '../../services/api.service';
import {detectAdblock} from '../../utils/adblock-detect';
import {detectAnyAdblocker} from 'just-detect-adblock';
import {DetectAdblockModalComponent} from '../../components/detect-adblock-modal/detect-adblock-modal.component';
import {VwuiModalRef, VwuiModalService} from '@recognizebv/vwui-angular';
import {MobileService} from '../../services/mobile.service';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html'
})
export class MapComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    vwuiModalRef: VwuiModalRef;
    isOnMobile = false;
    mobileNavbarEnabled$ = this.mobileService.navbarEnabled$;

    constructor(
        private apiService: ApiService,
        private mobileService: MobileService,
        private modalService: VwuiModalService,
        private router: Router,
        private searchStateService: SearchStateService,
        public activatedRoute: ActivatedRoute,
        public mapboxService: MapboxService,
        public pinnedLocationService: PinnedLocationService,
    ) {
    }

    ngOnInit() {
        if (environment.detectAdblock) {
            this.adblockCheck();
        }

        this.subscriptions.push(
            this.mobileService.isOnMobile$.subscribe((isOnMobile) => {
                this.isOnMobile = isOnMobile;
                if (!isOnMobile) {
                    this.mapboxService.init();
                }
                if (this.isOnMobile && this.activatedRoute.firstChild === null) {
                    this.router.navigate(['search'], {replaceUrl: true});
                }
            }),
            combineLatest([
                this.router.events.pipe(
                    filter((event) => event instanceof NavigationEnd),
                    startWith(this.router)
                ),
                this.apiService.allCompaniesAndProjectsForMap$
            ]).pipe(
                filter(() => this.activatedRoute.firstChild?.component === undefined
                    || this.activatedRoute.firstChild?.component === LayersComponent)
            ).subscribe(([_, points]) => {
                this.searchStateService.pointResults$.next(points);
            }),
            this.router.events
                .pipe(
                    filter((event) => event instanceof NavigationEnd),
                    startWith(this.router)
                )
                .subscribe(() => {
                    const activePage = this.activatedRoute.firstChild?.component;
                    if (activePage === undefined || activePage === SearchComponent || activePage === ProjectsComponent
                        || activePage === CompaniesComponent) {
                        this.pinnedLocationService.windowLocation$.next(activePage === undefined ? 'center' : 'right');
                        if (this.pinnedLocationService.pinnedLocationState$.value === 'disabled') {
                            this.pinnedLocationService.pinnedLocationState$.next('pinning');
                        }
                    } else {
                        this.pinnedLocationService.pinnedLocationState$.next('disabled');
                    }
                })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    async adblockCheck() {
        const results = await Promise.all([detectAdblock(), detectAnyAdblocker()]);
        if (results.some(it => it === true)) {
            this.vwuiModalRef = this.modalService.open(DetectAdblockModalComponent, {
                modalClass: 'modal-dialog-centered'
            });
        }
    }
}
